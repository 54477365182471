import globalConfigJSON from './global.config.json';
import serviceAccountJSON from './hatmaryoav-site-firebase-adminsdk-mvehv-9c5cc50fd9.json';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

export const globalConfig = JSON.parse(JSON.stringify(globalConfigJSON));

// ייצוא תצורת Firebase
const firebaseConfigObject = globalConfig.data.landingPageProject.firebase;
export const firebaseCollections = firebaseConfigObject.collections;
const firebaseConfigData = firebaseConfigObject.configuration;
const serviceAccount = JSON.parse(JSON.stringify(serviceAccountJSON));


export const firebaseConfig = {
     collections: firebaseCollections,
     serviceAccount: serviceAccount,
     configuration: firebaseConfigData,

}
